import React from "react";
import "./index.scss";
import { portfolioData } from "../data/portfolio";
import Navbar from "./Navbar";

const Portfolio = () => { 

    const renderPortfolio = (portfolio, idx) => {
        return (
            <div className="images-container" key={idx} style={{width: '100%'}}>
               {
                    portfolio.map((port, idx) => {
                        return (
                            <div className="image-box" key={idx} style={{width: '100%'}}>
                                <img 
                                src={port.cover}
                                className="portfolio-image"
                                alt="portfolio"></img>
                                <div className="content">
                                    <p className="title">{port.title}</p>
                                    <h4 className="description">{port.description}</h4>
                                    <p className="stack">{port.stack}</p>
                                    <button
                                        className="btn"
                                        onClick={() => window.open(port.url)}
                                    >View Project</button>
                                    <button
                                        className="btn"
                                        onClick={() => window.open(port.src)}
                                    >View Repo</button>
                                </div>
                            </div>
                        )
                    })
              }
            </div>
        );
    }


    return (
        <>
            <Navbar/>
            <div className="container portfolio-page" style={{width: '100%'}}>
                <h1 className="page-title">
                    Our Portfolio
                </h1>
                <div>{renderPortfolio(portfolioData)}</div>
            </div>
        </>
    );
}

export default Portfolio;