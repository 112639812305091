import React from 'react'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { 
            PageContainer, 
            Text, 
            Header, 
            Description, 
            FormContainer, 
            Form, 
            FormList, 
            FormItem, 
            FormInput, 
            FormMessage, 
            FormSubmit 
        } 
from './ContactElements'
import Navbar from './Navbar'

const SignIn = () => {
  const refForm = useRef()
  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
        .sendForm(
            'service_h3wofme',
            'template_ze51bkv',
            refForm.current,
            'T2cUUKjKm8TM2iJVJ'
        )
        .then(
            () => {
                alert('Message sent!')
                window.location.reload(false)
            },
            () => {
                alert('Failed to send, please try again.')
            }
        )
}
  return (
      <>
        <PageContainer className='container contact-page'>
            <Navbar/>
            <Text className='text-zone'>
                <Header>
                   Contact Us
                </Header>
                <Description style={{color: 'black', fontSize: '14px', fontWeight: '700'}}>
                  Please let us know about your project and our team will get back to you!
                </Description>
                <FormContainer className='contact-form'>
                    <Form ref={refForm} onSubmit={sendEmail}>
                        <FormList>
                            <FormItem className='half'>
                                <FormInput 
                                    type='text' 
                                    name='name' 
                                    placeholder='Name'
                                    required 
                                />
                            </FormItem>
                            <FormItem className='half'>
                                <FormInput 
                                    type='email'
                                    name='email' 
                                    placeholder='Email' 
                                    required 
                                />
                            </FormItem>
                            <FormItem className='full'>
                                <FormInput 
                                    placeholder="subject" 
                                    type='text' 
                                    name='subject' 
                                    required 
                                /> 
                            </FormItem>
                            <FormItem className='full'>
                                <FormMessage 
                                    placeholder='Message' 
                                    name='message' 
                                    required
                                ></FormMessage>
                            </FormItem>
                            <FormItem>
                                <FormSubmit type='submit' className='flat-button' value='SEND'/>
                            </FormItem>
                        </FormList>
                    </Form>
                </FormContainer>
            </Text>
           </PageContainer>
    </>
  )
}

export default SignIn
