import './App.css';
import {BrowserRouter as Router, Switch, Route} from  'react-router-dom'
import Home from './pages';
import SigninPage from './pages/signin';
import Portfolio from './components/Portfolio';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/contact' component={SigninPage} exact />
        <Route parth='/portfolio' component={Portfolio} exact />
      </Switch>
    </Router>
  );
}

export default App;
