import React from 'react'
import Icon1 from '../../images/webdev.svg'
import Icon2 from '../../images/design.svg'
import Icon3 from '../../images/seo.svg'
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from './ServiceElements'

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1}/>
          <ServicesH2>Web Development</ServicesH2>
          <ServicesP>Transforming your ideas into functional and secure websites, ensuring they perform optimally and provide exceptional user experiences.</ServicesP>
        </ServicesCard>
        <ServicesCard>
        <ServicesIcon src={Icon2}/>
          <ServicesH2>Web Design</ServicesH2>
          <ServicesP>Crafting visually captivating and user-centric designs that make a lasting impression and enhance your brand's online presence.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3}/>
          <ServicesH2>SEO Optimization</ServicesH2>
          <ServicesP>Elevating your website's visibility and rankings in search engines, driving organic traffic and maximizing your online potential.</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
