import React from 'react'
import { Nav, NavbarContainer, NavLogo, NavBtnLink } from '../components/Navbar/NavbarElements'

const Navbar = () => {

  return (
    <>
      <Nav style={{background: 'black'}}>
        <NavbarContainer>
          <NavLogo to='/'>VividWeb</NavLogo>
          <nav style={{display: 'flex', alignItems: 'center'}}>
            <NavBtnLink to='/portfolio'>Portfolio</NavBtnLink>
          </nav>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
