import svg05 from '../../images/svg05.svg'
import talk from '../../images/talking.svg'

export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lighttextDesc: true,
  topLine: 'Premium Web Development and Design',
  headline: 'Digital Partners in Success',
  description: 'At VividWeb, we believe that web development should be seamless and stress-free. Our mission is to simplify the complexities of the digital world, making it accessible to everyone.',
  buttonLabel: 'Our Projects',
  imgStart: false,
  img: svg05,
  alt: 'web',
  dark: true,
  primary: true,
  darkText: false,
  link: '/portfolio'
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Streamlined Process',
  headline: 'We Value Communication and Transparency',
  description: 'The journey begins with a conversation. We\'ll schedule an initial consultation where we listen carefully to your goals, ideas, and requirements. This is our opportunity to understand your vision and align it with our expertise.',
  buttonLabel: 'Contact Us',
  imgStart: true,
  img: talk,
  alt: 'Talk',
  dark: false,
  primary: false,
  darkText: true,
  link: '/contact'
};

// export const homeObjThree = {
//   id: 'signup',
//   lightBg: true,
//   lightText: false,
//   lighttextDesc: false,
//   topLine: 'Premium Bank',
//   headline: 'Creating an account is extremely easy',
//   description: 'Get access to our exclusive app that allows you to send unlimited transactions whitout getting charged any fees.',
//   buttonLabel: 'Start Now',
//   imgStart: false,
//   img: '/images/svg-3.svg',
//   alt: 'car',
//   primary: false,
//   darkText: true
// };
