import React from 'react'
import { Nav, NavbarContainer, NavLogo, NavBtnLink } from '../../components/Navbar/NavbarElements'
const Navbar = () => {
  
  return (
    <>
      <Nav style={{background: 'black', width: '100%'}}>
        <NavbarContainer style={{width: '100%'}}>
          <NavLogo to='/'>VividWeb</NavLogo>
          <nav style={{display: 'flex', alignItems: 'center'}}>
            <NavBtnLink to='/contact'>Contact</NavBtnLink>
          </nav>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
